import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, message, Select, Space, Table } from 'antd';
import axios from 'axios';
import copy from 'copy-to-clipboard';
import BaseView from '../../components/BaseView';

const name = 'el';
function El() {
  const [link, setLink] = useState([]);
  const [form] = Form.useForm();
  const [canClick, setCanClick] = useState(true);

  useEffect(() => {
    try {
      const json = JSON.parse(localStorage.getItem(`${name}_url_history`));
      setLink(json || []);
    } catch (e) {
      setLink([]);
    }
    try {
      const form_json = JSON.parse(localStorage.getItem(`${name}_form`));
      form.setFieldsValue(form_json);
    } catch (e) {}
  }, []);

  const handleLink = (e) => {
    axios
      .get(`${name}/getLink`, {
        params: { ...e, user: form.getFieldValue('user') },
      })
      .then((res) => {
        const _res = (res || []).filter((item) => item.ir >= 10);
        setLink(_res);
        //setCanClick(false);
        if (_res && _res.length > 0)
          localStorage.setItem(`${name}_url_history`, JSON.stringify(_res));
      })
      .catch((e) => {
        setLink([]);
      });
  };
  return (
    <BaseView name={name}>
      <Card
        title='操作'
        extra={
          <Space>
            {/* <Button
              onClick={() => {
                axios.get(`${name}/getIp`).then((res) => {
                  message.success(res);
                  setCanClick(true);
                });
              }}
            >
              检测IP
            </Button> */}
            <Form
              onFinish={(e) => {
                setLink(null);
                handleLink(e);
              }}
              style={{ height: '32px' }}
            >
              <Space>
                <Form.Item>
                  <Button
                    htmlType='submit'
                    type='primary'
                    loading={link === null}
                    disabled={!canClick}
                  >
                    获取问卷
                  </Button>
                </Form.Item>
              </Space>
            </Form>
          </Space>
        }
      >
        <Form
          form={form}
          onFinish={(a) => {
            axios.post(`${name}/addName`, a).then(() => {
              message.success('提交成功');
              setCanClick(true);
              localStorage.setItem(`${name}_form`, JSON.stringify(a));
            });
          }}
          initialValues={{
            sex: 0,
          }}
          onChange={() => {
            //setCanClick(false);
          }}
        >
          <Form.Item label='用户名' name='user' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label='性别' name='sex' rules={[{ required: true }]}>
            <Select>
              <Select.Option value={0}>男</Select.Option>
              <Select.Option value={1}>女</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label='年龄' name='age' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label='邮政偏码' name='email' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item label='年收入' name='income' rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item>
            <Button htmlType='submit' type='primary'>
              提交
            </Button>
          </Form.Item>
        </Form>
        <Table
          dataSource={link}
          rowKey={(record) => record.project_id}
          columns={[
            {
              title: 'ID',
              dataIndex: 'project_id',
              sorter: {
                compare: (a, b) => a.project_id - b.project_id,
                multiple: 4,
              },
            },
            {
              title: '名称',
              dataIndex: 'name',
              sorter: {
                compare: (a, b) => a.name.localeCompare(b.name, 'zh-CN'),
                multiple: 5,
              },
            },
            {
              title: '剩余数',
              dataIndex: 'remaining_completes',
              sorter: {
                compare: (a, b) =>
                  a.remaining_completes - b.remaining_completes,
                multiple: 3,
              },
            },
            {
              title: '通过率',
              dataIndex: 'ir',
              sorter: {
                compare: (a, b) => a.ir - b.ir,
                multiple: 2,
              },
            },
            {
              title: '地区/国家',
              dataIndex: 'country',
              sorter: {
                compare: (a, b) => a.country.localeCompare(b.country, 'zh-CN'),
                multiple: 5,
              },
            },
            {
              title: '金额',
              dataIndex: 'cpi',
              sorter: {
                compare: (a, b) => a.cpi - b.cpi,
                multiple: 1,
              },
            },
            {
              title: '操作',
              render: (value, record) => (
                <Space>
                  <Button
                    type='primary'
                    onClick={() => {
                      window.open('/api/notify/el/pe?id=' + record.project_id);
                    }}
                  >
                    配额
                  </Button>
                  <Button
                    type='primary'
                    onClick={() => {
                      window.open(record.entry_link);
                    }}
                  >
                    打开
                  </Button>
                  <Button
                    onClick={() => {
                      copy(record.entry_link);
                      message.success('复制成功');
                    }}
                  >
                    复制
                  </Button>
                </Space>
              ),
            },
          ]}
        />
      </Card>
    </BaseView>
  );
}
export default El;
